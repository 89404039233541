.tech {
  margin-top: 2.5rem;
  display: flex;
  padding: 0 0.5rem;
  margin-right: 4rem;
  width: auto;
  position: relative;
  flex-direction: row;

  @include respond(tab-port) {
  }

  &__shape {
    background-color: $color-grey-light-2;
    padding: 1rem 0;
    height: 6rem;
    width: 6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 0 4rem rgba($color-black, 0.1);

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: circle(50% at 50% 50%);
      clip-path: circle(50% at 50% 50%);
      -webkit-shape-outside: circle(50% at 50% 50%);
      shape-outside: circle(50% at 50% 50%);
      border-radius: none;
    }

    //Add animation for tech circle to come up
  }

  &:hover &__img {
    transform: scale(1.6);
  }

  &__img {
    height: 4rem;
    border-radius: 50%; //circle
    transition: all 0.4s;
  }

  &__label {
    display: flex;
    position: absolute;
    //Centering code
    top: 50%;
    left: 50%;
    transform: translate(-50%, 150%);

    font-size: 1.2rem;
    font-weight: 700;
    display: block;
    transition: all 0.5s;
  }

  &__text {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: inherit;
  }
}
