.section-about {
  position: relative;
  background-color: $color-grey;
  padding: 10rem 0;

  @include respond(tab-port) {
    padding: 8rem 0;
  }
}

.section-tech {
  background-color: $color-grey;
  padding: 5rem 0;

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  @include respond(tab-land) {
    padding: 2rem 3rem;
  }
}

.section-projects {
  background-color: $color-primary;
  padding: 5rem 0 15rem 0;

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  @include respond(tab-land) {
    padding: 2rem 3rem;
  }
}
