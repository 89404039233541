.header {
  height: 75vh;
  background-size: cover;
  background-color: $color-primary;
  background-position: top;
  display: flex;
  justify-content: center;

  &__nav-box {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &__list {
    display: flex;
    flex-direction: row;
    list-style: none;
  }

  &__item {
    font-size: 1.5rem;
    padding: 0 3rem;
  }

  &__link {
    text-decoration: none;
    color: $color-grey-light-1;
    cursor: pointer;
  }

  &__link__active {
    border-bottom: 1px solid $color-red-1;
  }

  &__logo {
    height: 3.5rem;
  }

  &__text-box {
    padding-right: 0 0 0 0;
    display: flex;
    justify-content: center;
    margin-top: 25rem;
    text-align: center;
  }
}
