.about {
  width: 75%;
  margin: 0 auto;
  box-shadow: 0 3rem 6rem rgba($color-black, 0.1);
  background-color: rgba($color-white, 0.6);
  border-radius: 3px; //rounded corners
  padding: 6rem;
  padding-left: 9rem;
  font-size: $default-font-size;

  @include respond(tab-port) {
    width: 100%;
    padding: 4rem;
  }

  &__shape {
    margin: 1.5rem;
    width: 15rem;
    height: 15rem;
    float: left;
    position: relative;
    overflow: hidden;
    border-radius: 50%; //circle

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: circle(50% at 50% 50%);
      clip-path: circle(50% at 50% 50%);
      -webkit-shape-outside: circle(50% at 50% 50%);
      shape-outside: circle(50% at 50% 50%);
      border-radius: none;
    }
  }

  &__img {
    height: 100%;
    //transform: scale(1); //center image
    backface-visibility: hidden;
  }
}
