.footer {
  background-color: $color-grey;
  padding: 10rem 0;
  font-size: 1.4rem;
  color: $color-grey-dark-3;

  @include respond(tab-port) {
    padding: 8rem 0;
  }

  @include respond(tab-land) {
    padding: 8rem 3rem;
  }

  &__github {
    height: 4rem;
    width: 4rem;
    fill: $color-grey-dark;
    transition: all 0.2s;

    &:hover {
      fill: $color-black;
      transform: scale(1.05);
    }
  }

  &__mail {
    height: 4rem;
    width: 4rem;
    fill: $color-grey-dark;
    transition: all 0.2s;

    &:hover {
      fill: #ea4335;
      transform: scale(1.05);
    }
  }

  &__linkedin {
    height: 4rem;
    width: 4rem;
    fill: $color-grey-dark;
    transition: all 0.2s;

    &:hover {
      fill: #0072c6;
      transform: scale(1.05);
    }
  }

  &__navigation {
    border-top: 1px solid $color-grey-dark;
    padding-top: 2rem;
    display: inline-block;

    @include respond(tab-port) {
      width: 100%;
      text-align: center;
    }
  }

  &__list {
    list-style: none;
  }

  &__item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  // &__link {
  //   &:link,
  //   &:visited {
  //     color: $color-grey-light-1;
  //     background-color: $color-grey-dark-3;
  //     text-decoration: none;
  //     text-transform: uppercase;
  //     display: inline-block;
  //     transition: all 0.2s;
  //   }

  //   &:hover,
  //   &:active {
  //     color: $color-primary;
  //     box-shadow: 0 1rem 2rem rgba($color-black, 0.4);
  //     transform: rotate(5deg) scale(1.3);
  //   }
  // }

  &__copyright {
    border-top: 1px solid $color-grey-dark;
    padding-top: 2rem;
    width: 80%;
    float: right;

    @include respond(tab-port) {
      width: 100%;
      float: none;
    }
  }
}
